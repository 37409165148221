<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <Table
        :headers="headers"
        :items="posts"
        :loading="loading"
        :total-records="pagination ? pagination.records_total : null"
        :number-of-pages="pagination ? pagination.total_pages : null"
        :items-per-page="50"
        :options.sync="options"
        :footer="false"
        @click:row="edit"
      >
        <template v-slot:top>
          <v-row no-gutters align="center" class="mb-7">
            <v-col>
              <h3 class="page-title font-weight-bold ma-0">お知らせ一覧</h3>
            </v-col>
            <v-col class="text-right">
              <v-btn
                height="30"
                class="btn-new"
                color="#CFD3FE"
                depressed
                min-width="100"
                to="/post/create"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                新規作成
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.id="{ item }">
        <span class="post-id"> {{item.id}} </span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-icon size="16" class="pb-1" :color="getColor(item.status)"
            >$dot</v-icon
          >
        </template>

        <template v-slot:item.title="{ item }">
          {{
            item.title.length > 30
              ? item.title.substring(0, 30) + '...'
              : item.title
          }}
        </template>

        <template v-slot:item.content="{ item }">
          {{
            item.content.length > 60
              ? item.content.substring(0, 60) + '...'
              : item.content
          }}
        </template>

        <template v-slot:item.users="{ item }">
          <div class="person-blk">
            <span v-for="(user, index) in item.users" :key="index">
              {{ user.last_name }}
              <v-icon :color="user.pivot.is_read == 1 ? 'green' : '#ccc'" small>
                mdi-checkbox-marked-circle
              </v-icon>
            </span>
          </div>
          <!-- {{ item.users.map(x => x.last_name).join(', ') }} -->
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn color="primary mr-2" fab x-small :to="{ name: 'PostEdit', params: { id: item.id }}">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      headers: [
        {
          text: '#',
          value: 'id',
          align: 'center',
          sortable: false,
        },
        { text: '日付', value: 'post_date', align: 'center' },
        // { text: '公開', value: 'status', align: 'center' },
        { text: 'タイトル', value: 'title' },
        { text: '内容', value: 'content' },
        {
          text: '対象者',
          value: 'users',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      loading: true,
      options: {}
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },

  computed: {
    posts() {
      return this.$store.getters.allposts
    },
    pagination() {
      return this.$store.getters.postPagination
    },
    reqParams() {
      return {
        paginate: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      }
    }
  },

  methods: {
    async getDataFromApi() {
      this.loading = true
      await this.$store.dispatch('POST_GET_ALL', this.reqParams)
      this.loading = false
    },

    edit(item) {
      this.$router.push({ name: 'PostEdit', params: { id: item.id } })
    },

    getColor(status) {
      if (status == '1') {
        return 'green'
      } else {
        return 'red'
      }
    }
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
