var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('Table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.posts,
      "loading": _vm.loading,
      "total-records": _vm.pagination ? _vm.pagination.records_total : null,
      "number-of-pages": _vm.pagination ? _vm.pagination.total_pages : null,
      "items-per-page": 50,
      "options": _vm.options,
      "footer": false
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      },
      "click:row": _vm.edit
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "mb-7",
          attrs: {
            "no-gutters": "",
            "align": "center"
          }
        }, [_c('v-col', [_c('h3', {
          staticClass: "page-title font-weight-bold ma-0"
        }, [_vm._v("お知らせ一覧")])]), _c('v-col', {
          staticClass: "text-right"
        }, [_c('v-btn', {
          staticClass: "btn-new",
          attrs: {
            "height": "30",
            "color": "#CFD3FE",
            "depressed": "",
            "min-width": "100",
            "to": "/post/create"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-plus ")]), _vm._v(" 新規作成 ")], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "post-id"
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "pb-1",
          attrs: {
            "size": "16",
            "color": _vm.getColor(item.status)
          }
        }, [_vm._v("$dot")])];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.title.length > 30 ? item.title.substring(0, 30) + '...' : item.title) + " ")];
      }
    }, {
      key: "item.content",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.content.length > 60 ? item.content.substring(0, 60) + '...' : item.content) + " ")];
      }
    }, {
      key: "item.users",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "person-blk"
        }, _vm._l(item.users, function (user, index) {
          return _c('span', {
            key: index
          }, [_vm._v(" " + _vm._s(user.last_name) + " "), _c('v-icon', {
            attrs: {
              "color": user.pivot.is_read == 1 ? 'green' : '#ccc',
              "small": ""
            }
          }, [_vm._v(" mdi-checkbox-marked-circle ")])], 1);
        }), 0)];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-btn', {
          attrs: {
            "color": "primary mr-2",
            "fab": "",
            "x-small": "",
            "to": {
              name: 'PostEdit',
              params: {
                id: item.id
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }